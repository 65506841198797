import React, { useState, useEffect } from 'react';
import Sidebar from '../shared/sidebar';
import Navbar from '../shared/navbar';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { FaPlus, FaSave } from 'react-icons/fa';

const Kartu_pengecekan = () => {
  const [items, setItems] = useState([
    {
      id: Date.now(),
      tujuan: '',
      nama: '',
      lot: '',
      jenis: '',
      val_jenis: '',
      cones: '',
      bale: '',
    },
  ]);
  const [formData, setFormData] = useState({
    idmuat: '',
    tanggal: '',
    security: '',
    checker: '',
    nopol: '',
    driver1: '',
    driver2: '',
    forklift1: '',
    forklift2: '',
    jammuat: '',
    jamselesai: '',
    personel1: '',
    personel2: '',
    personel3: '',
    personel4: '',
    totbale: 0,
  });

  const [latestId, setLatestId] = useState('');

  const fetchLatestId = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/latestId');
      setLatestId(response.data.latestId);
      setFormData((prevData) => ({
        ...prevData,
        idmuat: response.data.latestId,
      }));
    } catch (error) {
      console.error('Error fetching latest ID:', error);
    }
  };

  useEffect(() => {
    fetchLatestId();
  }, []);

  const [securityOptions, setSecurityOptions] = useState([]);
  const [gudangOptions, setGudangOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);

  // Fetch security options from API
  const fetchSecurityOptions = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/daftar');
      const securityFilteredOptions = response.data.filter(
        (item) => item.posisi === 'keamanan'
      );
      const gudangFilteredOptions = response.data.filter(
        (item) => item.posisi === 'gudang'
      );
      const driverFilteredOptions = response.data.filter(
        (item) => item.posisi === 'driver'
      );

      setSecurityOptions(securityFilteredOptions);
      setGudangOptions(gudangFilteredOptions);
      setDriverOptions(driverFilteredOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    fetchSecurityOptions();
  }, []);

  const [nopolOptions, setNopolOptions] = useState([]);

  const fetchNopolOptions = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/nopol');
      setNopolOptions(response.data);
    } catch (error) {
      console.error('Error fetching No. Polisi options:', error);
    }
  };

  useEffect(() => {
    fetchNopolOptions();
  }, []);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id: Date.now(),
        tujuan: '',
        nama: '',
        lot: '',
        jenis: '',
        val_jenis: '',
        cones: '',
        bale: '',
      },
    ]);
  };

  const handleInputChange = (id, e) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const calculateTotBale = () => {
    return items.reduce((total, item) => {
      return total + (parseInt(item.bale) || 0);
    }, 0);
  };

  const handleRemoveItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalBale = calculateTotBale();

    const payload = {
      ...formData,
      totbale: totalBale,
      items: items.map((item) => ({
        tujuan: item.tujuan,
        nama: item.nama,
        lot: item.lot,
        jenis: item.jenis,
        val_jenis: item.val_jenis,
        cones: item.cones,
        bale: item.bale,
      })),
    };

    console.log(payload);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        'http://127.0.0.1:8000/api/kartupengecekan',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      const generatedId = response.data.idmuat;

      Swal.fire({
        icon: 'success',
        title: 'Data Saved Successfully!',
        text: 'The data has been successfully saved.',
      });

      setFormData({
        idmuat: generatedId,
        tanggal: '',
        security: '',
        checker: '',
        nopol: '',
        driver1: '',
        driver2: '',
        forklift1: '',
        forklift2: '',
        jammuat: '',
        jamselesai: '',
        personel1: '',
        personel2: '',
        personel3: '',
        personel4: '',
        totbale: 0,
      });
      setItems([
        {
          id: Date.now(),
          tujuan: '',
          nama: '',
          lot: '',
          jenis: '',
          val_jenis: '',
          cones: '',
          bale: '',
        },
      ]);
      // window.location.reload();
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      );

      // SweetAlert error message
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while saving the data. Please try again.',
      });
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
        <Navbar />
        <div className="p-4 grid grid-cols-1 md:grid-cols-1 gap-4 dark:bg-gray-900 dark:text-white">
          <div className="bg-white shadow-md rounded-lg p-4 md:col-span-1 lg:col-span-3 dark:bg-gray-900 dark:text-white">
            <h2 className="text-xl font-semibold mb-4">Form Pengecekan</h2>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium">ID:</label>
                  <input
                    type="text"
                    name="idmuat"
                    value={formData.idmuat}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Tanggal:</label>
                  <input
                    type="date"
                    name="tanggal"
                    value={formData.tanggal}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Security:</label>
                  <select
                    name="security"
                    value={formData.security}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Security</option>
                    {securityOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Checker Gudang:
                  </label>
                  <select
                    name="checker"
                    value={formData.checker}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Checker Gudang</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    No. Polisi:
                  </label>
                  <select
                    name="nopol"
                    value={formData.nopol}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih No. Polisi</option>
                    {nopolOptions.map((nopol) => (
                      <option key={nopol.id} value={nopol.plat_no}>
                        {nopol.plat_no}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">Driver 1:</label>
                  <select
                    name="driver1"
                    value={formData.driver1}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Driver 1</option>
                    {driverOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Driver 2:</label>
                  <select
                    name="driver2"
                    value={formData.driver2}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Driver 2</option>
                    {driverOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium">Jam Muat:</label>
                  <input
                    type="time"
                    name="jammuat"
                    value={formData.jammuat}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Jam Selesai:
                  </label>
                  <input
                    type="time"
                    name="jamselesai"
                    value={formData.jamselesai}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Forklift 1:
                  </label>
                  <select
                    name="forklift1"
                    value={formData.forklift1}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Forklift 1</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Forklift 2:
                  </label>
                  <select
                    name="forklift2"
                    value={formData.forklift2}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Forklift 2</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Personel 1:
                  </label>
                  <select
                    name="personel1"
                    value={formData.personel1}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Personel 1</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Personel 2:
                  </label>
                  <select
                    name="personel2"
                    value={formData.personel2}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Personel 2</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Personel 3:
                  </label>
                  <select
                    name="personel3"
                    value={formData.personel3}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Personel 3</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium">
                    Personel 4:
                  </label>
                  <select
                    name="personel4"
                    value={formData.personel4}
                    onChange={handleFormChange}
                    className="mt-1 p-2 border rounded w-full"
                  >
                    <option value="">Pilih Personel 4</option>
                    {gudangOptions.map((option) => (
                      <option key={option.id} value={option.nama}>
                        {option.nama}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  onClick={handleAddItem}
                  className="bg-green-500 text-white p-1 rounded w-auto flex items-center gap-2"
                >
                  <FaPlus />
                  Muat
                </button>
              </div>

              {items.map((item) => (
                <div
                  key={item.id}
                  className="mb-4 border rounded p-4 bg-green-500"
                >
                  <div className="flex justify-end mb-4 bg-green-500">
                    <button
                      type="button"
                      onClick={() => handleRemoveItem(item.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 0a1 1 0 0 1 1 1V2h4V1a1 1 0 0 1 2 0V2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H13v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h1V1a1 1 0 0 1 1-1h3zm0 1H4v1h1V1zM4 4v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4H4z" />
                      </svg>
                    </button>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Tujuan:
                      </label>
                      <input
                        type="text"
                        name="tujuan"
                        value={item.tujuan}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Nama Barang:
                      </label>
                      <input
                        type="text"
                        name="nama"
                        value={item.nama}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Lot:
                      </label>
                      <input
                        type="text"
                        name="lot"
                        value={item.lot}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Jenis:
                      </label>
                      <select
                        name="jenis"
                        value={item.jenis}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      >
                        <option value="">Pilih Jenis</option>
                        <option value="Box">Box</option>
                        <option value="Karung">Karung</option>
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Val Jenis:
                      </label>
                      <input
                        type="text"
                        name="val_jenis"
                        value={item.val_jenis}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>

                    {/* Cones Field */}
                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Cones:
                      </label>
                      <input
                        type="number"
                        name="cones"
                        value={item.cones}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {/* Bale Field */}
                    <div className="flex items-center">
                      <label className="block text-sm font-medium mr-2 w-1/3">
                        Bale:
                      </label>
                      <input
                        type="number"
                        name="bale"
                        value={item.bale}
                        onChange={(e) => handleInputChange(item.id, e)}
                        className="mt-1 p-2 border rounded w-2/3"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-end mb-4">
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-1 rounded w-auto flex items-center gap-2"
                >
                  <FaSave />
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kartu_pengecekan;
