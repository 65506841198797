import React, { useContext, useState } from 'react';
import { FaMoon, FaSun, FaCog, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { ThemeContext } from '../../context/ThemeContextProvide';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className="bg-gray-100 text-gray-900 border-b border-gray-300 p-4 flex justify-between items-center dark:border-gray-600 dark:bg-gray-900 dark:text-white relative">
      <h1>Laporan Supir PT. International Textile Plumbon</h1>
      <div className="flex items-center">
        <button className="text-2xl" onClick={toggleTheme}>
          {theme === 'light' ? <FaMoon /> : <FaSun />}
        </button>

        {/* Foto Profil */}
        <div className="relative ml-4">
          <img
            src="./hrd.png"
            alt="Profile"
            className="w-8 h-8 rounded-full cursor-pointer"
            onClick={handleDropdownToggle}
          />

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-10 dark:border-gray-600 dark:bg-gray-900 dark:text-white">
              <ul className="py-1">
                <li className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer">
                  <FaUser className="mr-2" />
                  <span>Users</span>
                </li>
                <li className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer">
                  <FaCog className="mr-2" />
                  <span>Settings</span>
                </li>
                <li
                  className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="mr-2" />
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
