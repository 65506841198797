import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { FaUser, FaLock } from 'react-icons/fa';
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/login', {
        username: username,
        password: password,
      });

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('name', response.data.user.name);
      localStorage.setItem('role', response.data.user.role);

      NotificationManager.success('Login berhasil!', 'Success', 3000);
      onLogin();
      navigate('/dashboard');
    } catch (error) {
      NotificationManager.error(
        'Login gagal! Periksa username dan password Anda.',
        'Error',
        5000
      );
      setValidation('Login gagal! Periksa username dan password Anda.');
    }
  };

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">Welcome Back!</h1>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-box">
            <FaUser className="icon" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-box">
            <FaLock className="icon" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {validation && <p className="validation-error">{validation}</p>}
          <button type="submit" className="login-button">
            Sign In
          </button>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;
