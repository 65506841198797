import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Sidebar from '../shared/sidebar';
import Navbar from '../shared/navbar';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';

const Security = () => {
  const [daftarData, setDaftarData] = useState([]);
  const [noPolData, setNoPolData] = useState([]);
  const [showModalDaftar, setShowModalDaftar] = useState(false);
  const [showModalNoPol, setShowModalNoPol] = useState(false);
  const [formDataDaftar, setFormDataDaftar] = useState({
    nama: '',
    posisi: '',
  });
  const [formDataNoPol, setFormDataNoPol] = useState({ plat_no: '' });
  const [selectedData, setSelectedData] = useState(null);

  // Fetch data from API
  const fetchDaftar = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/daftar');
      setDaftarData(response.data);
    } catch (error) {
      console.error('Error fetching daftar:', error);
    }
  };

  const fetchNoPol = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/nopol');
      setNoPolData(response.data);
    } catch (error) {
      console.error('Error fetching no_pol:', error);
    }
  };

  useEffect(() => {
    fetchDaftar();
    fetchNoPol();
  }, []);

  // Handle form submit for "Tambah/Edit Daftar"
  const handleSubmitDaftar = async (e) => {
    e.preventDefault();
    try {
      if (selectedData) {
        await axios.put(
          `http://127.0.0.1:8000/api/daftar/update/${selectedData.id}`,
          formDataDaftar
        );
        Swal.fire('Berhasil!', 'Data berhasil diperbarui.', 'success');
      } else {
        await axios.post(
          'http://127.0.0.1:8000/api/store/daftar',
          formDataDaftar
        );
        Swal.fire('Berhasil!', 'Daftar berhasil ditambahkan.', 'success');
      }
      fetchDaftar();
      setShowModalDaftar(false);
      setFormDataDaftar({ nama: '', posisi: '' });
      setSelectedData(null);
    } catch (error) {
      Swal.fire('Gagal!', 'Terjadi kesalahan.', 'error');
    }
  };

  // Handle form submit for "Tambah/Edit No Pol"
  const handleSubmitNoPol = async (e) => {
    e.preventDefault();
    try {
      if (selectedData) {
        await axios.put(
          `http://127.0.0.1:8000/api/nopol/update/${selectedData.id}`,
          formDataNoPol
        );
        Swal.fire('Berhasil!', 'Data berhasil diperbarui.', 'success');
      } else {
        await axios.post(
          'http://127.0.0.1:8000/api/nopol/store',
          formDataNoPol
        );
        Swal.fire('Berhasil!', 'No Pol berhasil ditambahkan.', 'success');
      }
      fetchNoPol();
      setShowModalNoPol(false);
      setFormDataNoPol({ plat_no: '' });
      setSelectedData(null);
    } catch (error) {
      Swal.fire('Gagal!', 'Terjadi kesalahan.', 'error');
    }
  };

  // Handle delete
  const handleDelete = async (id, isNoPol = false) => {
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Data yang dihapus tidak dapat dikembalikan!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ya, hapus!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (isNoPol) {
            await axios.delete(`http://127.0.0.1:8000/api/nopol/destroy/${id}`);
          } else {
            await axios.delete(
              `http://127.0.0.1:8000/api/daftar/destroy/${id}`
            );
          }
          Swal.fire('Berhasil!', 'Data berhasil dihapus.', 'success');
          fetchDaftar();
          fetchNoPol();
        } catch (error) {
          Swal.fire('Gagal!', 'Terjadi kesalahan.', 'error');
        }
      }
    });
  };

  // Handle modal open for edit
  const handleEdit = (data, isNoPol = false) => {
    setSelectedData(data);
    if (isNoPol) {
      setFormDataNoPol({ plat_no: data.plat_no });
      setShowModalNoPol(true);
    } else {
      setFormDataDaftar({ nama: data.nama, posisi: data.posisi });
      setShowModalDaftar(true);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="grow ml-5 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
        <Navbar />
        <div className="p-4">
          <div className="rounded-lg shadow-lg p-6 dark:bg-gray-900 dark:text-white">
            <div className="flex justify-between mb-4">
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                onClick={() => setShowModalDaftar(true)}
              >
                <FaPlus className="mr-2" />
                Tambah Daftar
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                onClick={() => setShowModalNoPol(true)}
              >
                <FaPlus className="mr-2" />
                Tambah No Pol
              </button>
            </div>

            <div className="flex space-x-4">
              <div className="w-full md:w-1/2 p-2">
                <h3 className="text-lg font-semibold mb-2">Daftar</h3>
                <DataTable
                  columns={[
                    {
                      name: 'NAMA',
                      selector: (row) => row.nama,
                      sortable: true,
                    },
                    {
                      name: 'POSISI',
                      selector: (row) => row.posisi,
                      sortable: true,
                    },
                    {
                      name: 'AKSI',
                      cell: (row) => (
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleEdit(row, false)}
                            className="text-blue-500"
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => handleDelete(row.id, false)}
                            className="text-red-500"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      ),
                    },
                  ]}
                  data={daftarData}
                  pagination
                  highlightOnHover
                  striped
                  persistTableHead
                />
              </div>

              <div className="w-full md:w-1/2 p-2">
                <h3 className="text-lg font-semibold mb-2">No Pol</h3>
                <DataTable
                  columns={[
                    {
                      name: 'PLAT NOMOR',
                      selector: (row) => row.plat_no,
                      sortable: true,
                    },
                    {
                      name: 'AKSI',
                      cell: (row) => (
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleEdit(row, true)}
                            className="text-blue-500"
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => handleDelete(row.id, true)}
                            className="text-red-500"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      ),
                    },
                  ]}
                  data={noPolData}
                  pagination
                  highlightOnHover
                  striped
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modal Tambah/Edit Daftar */}
        {showModalDaftar && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-96">
              <h3 className="text-xl font-semibold mb-4">
                {selectedData ? 'Edit Daftar' : 'Tambah Daftar'}
              </h3>
              <form onSubmit={handleSubmitDaftar}>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Nama</label>
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                    value={formDataDaftar.nama}
                    onChange={(e) =>
                      setFormDataDaftar({
                        ...formDataDaftar,
                        nama: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">
                    Posisi
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                    value={formDataDaftar.posisi}
                    onChange={(e) =>
                      setFormDataDaftar({
                        ...formDataDaftar,
                        posisi: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                    onClick={() => {
                      setShowModalDaftar(false);
                      setSelectedData(null);
                      setFormDataDaftar({ nama: '', posisi: '' });
                    }}
                  >
                    Batal
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Modal Tambah/Edit No Pol */}
        {showModalNoPol && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-96">
              <h3 className="text-xl font-semibold mb-4">
                {selectedData ? 'Edit No Pol' : 'Tambah No Pol'}
              </h3>
              <form onSubmit={handleSubmitNoPol}>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">
                    Plat No
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                    value={formDataNoPol.plat_no}
                    onChange={(e) =>
                      setFormDataNoPol({
                        ...formDataNoPol,
                        plat_no: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                    onClick={() => {
                      setShowModalNoPol(false);
                      setSelectedData(null);
                      setFormDataNoPol({ plat_no: '' });
                    }}
                  >
                    Batal
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Security;
