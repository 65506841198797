import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Sidebar from '../shared/sidebar';
import Navbar from '../shared/navbar';
import * as XLSX from 'xlsx';
import { FaSearch, FaFileExcel, FaPrint } from 'react-icons/fa'; 

const Laporan = () => {
    const [filterText, setFilterText] = useState("");

    const columns = [
        {
            name: 'ID',
            selector: row => row.tgl,
            sortable: true
        },
        {
            name: 'TANGGAL',
            selector: row => row.security,
            sortable: true
        },
        {
            name: 'JAM MULAI',
            selector: row => row.nopol,
            sortable: true
        },
        {
            name: 'JAM SELESAI',
            selector: row => row.driver,
            sortable: true
        },
        {
            name: 'JUMLAH PERSONEL',
            selector: row => row.driver,
            sortable: true
        },
        {
            name: 'DURASI',
            selector: row => row.driver,
            sortable: true
        },
        {
            name: 'MAN HOUR',
            selector: row => row.driver,
            sortable: true
        },
        {
            name: 'TOTAL BALE DAY',
            selector: row => row.driver,
            sortable: true
        },
        {
            name: 'MH / BALE',
            selector: row => row.driver,
            sortable: true
        },
    ];

    // Sample data
    const data = [
        { id: 1, tgl: '2024-10-01', security: 'John Doe', nopol: 'B 1234 XY', driver: 'Adam Smith' },
        { id: 2, tgl: '2024-09-28', security: 'Jane Doe', nopol: 'B 5678 XY', driver: 'Michael Johnson' },
        { id: 3, tgl: '2024-09-25', security: 'Robert Williams', nopol: 'B 9876 AB', driver: 'Chris Evans' }
    ];

    // Export data to Excel
    const exportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data); 
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, "Pengecekan.xlsx"); 
    };

    const filteredData = data.filter(
        item => item.tgl.toLowerCase().includes(filterText.toLowerCase()) ||
                item.security.toLowerCase().includes(filterText.toLowerCase()) ||
                item.nopol.toLowerCase().includes(filterText.toLowerCase()) ||
                item.driver.toLowerCase().includes(filterText.toLowerCase())
    );
  return (
    <div className="flex">
            <Sidebar />
            <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
                <Navbar />
                <div className="p-4">
                    <div className="rounded-lg shadow-lg p-6 dark:bg-gray-900 dark:text-white">
                        <div className="flex justify-between mb-4">
                            <h3 className="text-xl font-semibold">List Print Pengecekan</h3>
                            <button
                                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                                onClick={exportExcel}
                            >
                                <FaFileExcel className="mr-2" />
                                Export to Excel
                            </button>
                        </div>

                        {/* Search input */}
                        <div className="mb-4 flex items-center space-x-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="pl-10 pr-4 py-2 border rounded-md w-64" 
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <FaSearch className="absolute top-2 left-3 text-gray-500" /> 
                            </div>
                        </div>

                        <DataTable
                            columns={columns}
                            data={filteredData} 
                            pagination
                            highlightOnHover
                            striped
                            persistTableHead
                        />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Laporan
