import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaTachometerAlt,
  FaUsers,
  FaCog,
  FaCreditCard,
  FaPrint,
  FaBook,
  FaListAlt,
  FaTruck,
} from 'react-icons/fa';

const Sidebar = () => {
  const role = localStorage.getItem('role'); // Ambil role dari localStorage

  return (
    <div className="bg-gray-100 text-gray-900 h-screen px-4 fixed w-16 md:w-64 border-r border-gray-300 dark:border-gray-600 dark:bg-gray-900 dark:text-white">
      <div className="flex items-center mt-4">
        <FaTruck className="w-12 h-12 text-blue-600 mr-2" />
        <h1 className="text-2xl font-bold hidden md:block italic">E-Supir</h1>
      </div>
      <ul className="flex flex-col mt-5 text-xl">
        {/* Menu Dashboard - Selalu ada */}
        <li className="flex items-center py-3 px-2 space-x-4">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              `flex items-center ${
                isActive ? 'text-blue-600' : 'hover:text-blue-600'
              } transition-colors duration-300`
            }
          >
            <FaTachometerAlt className="mr-2" />
            <span>Dashboard</span>
          </NavLink>
        </li>

        {/* Menu Daftar dan Users - Tampil hanya untuk role "super" */}
        {role === 'super' && (
          <>
            <li className="flex items-center py-3 px-2 space-x-4">
              <NavLink
                to="/daftar"
                className={({ isActive }) =>
                  `flex items-center ${
                    isActive ? 'text-blue-600' : 'hover:text-blue-600'
                  } transition-colors duration-300`
                }
              >
                <FaListAlt className="mr-2" />
                <span>Daftar</span>
              </NavLink>
            </li>

            <li className="flex items-center py-3 px-2 space-x-4">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `flex items-center ${
                    isActive ? 'text-blue-600' : 'hover:text-blue-600'
                  } transition-colors duration-300`
                }
              >
                <FaUsers className="mr-2" />
                <span>Users</span>
              </NavLink>
            </li>
          </>
        )}

        {/* Menu Kartu Pengecekan - Selalu ada */}
        <li className="flex items-center py-3 px-2 space-x-4">
          <NavLink
            to="/checklist"
            className={({ isActive }) =>
              `flex items-center ${
                isActive ? 'text-blue-600' : 'hover:text-blue-600'
              } transition-colors duration-300`
            }
          >
            <FaCreditCard className="mr-2" />
            <span>Kartu Pengecekan</span>
          </NavLink>
        </li>

        {/* Menu Print Kartu - Selalu ada */}
        <li className="flex items-center py-3 px-2 space-x-4">
          <NavLink
            to="/print"
            className={({ isActive }) =>
              `flex items-center ${
                isActive ? 'text-blue-600' : 'hover:text-blue-600'
              } transition-colors duration-300`
            }
          >
            <FaPrint className="mr-2" />
            <span>Print Kartu</span>
          </NavLink>
        </li>

        {/* Menu Laporan - Selalu ada */}
        <li className="flex items-center py-3 px-2 space-x-4">
          <NavLink
            to="/report"
            className={({ isActive }) =>
              `flex items-center ${
                isActive ? 'text-blue-600' : 'hover:text-blue-600'
              } transition-colors duration-300`
            }
          >
            <FaBook className="mr-2" />
            <span>Laporan</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
