import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import './App.css';
import Login from './components/auth/login';
import Dashboard from './components/home/dashboard';
import ThemeContextProvider from './context/ThemeContextProvide';
import Users from './components/pages/users';
import Kartu_pengecekan from './components/pages/kartu_pengecekan';
import Print from './components/pages/print';
import Laporan from './components/pages/laporan';
import Security from './components/pages/daftar';

const ProtectedRoute = ({ isAuthenticated, allowedRoles, children }) => {
  const location = useLocation();
  const userRole = localStorage.getItem('role'); // Ambil role dari localStorage

  useEffect(() => {
    if (!isAuthenticated) {
      Swal.fire({
        icon: 'warning',
        title: 'Akses Ditolak',
        text: 'Anda harus login untuk mengakses halaman ini.',
        timer: 3000,
        showConfirmButton: false,
      });
    } else if (allowedRoles && !allowedRoles.includes(userRole)) {
      Swal.fire({
        icon: 'error',
        title: 'Akses Ditolak',
        text: 'Anda tidak memiliki hak akses ke halaman ini.',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }, [isAuthenticated, allowedRoles, userRole]);

  if (!isAuthenticated || (allowedRoles && !allowedRoles.includes(userRole))) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return children;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return Boolean(localStorage.getItem('token'));
  });

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <ThemeContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                allowedRoles={['super']}
              >
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checklist"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                allowedRoles={['super', 'admin', 'security', 'gudang', 'supir']}
              >
                <Kartu_pengecekan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/print"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                allowedRoles={['super', 'admin', 'security', 'gudang', 'supir']}
              >
                <Print />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                allowedRoles={['super', 'admin', 'security', 'gudang', 'supir']}
              >
                <Laporan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/daftar"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                allowedRoles={['super']}
              >
                <Security />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeContextProvider>
  );
}

export default App;
