import React, { useEffect, useState } from 'react';
import Sidebar from '../shared/sidebar';
import Navbar from '../shared/navbar';
import Card from '../shared/card';
import { FaCog, FaShoppingCart, FaUser } from 'react-icons/fa';

const Dashboard = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const name = localStorage.getItem('name') || 'Supir';
    console.log('User Name:', name);
    setUserName(name);
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
        <Navbar />
        <div className="p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Kartu Selamat Datang */}
          <div className="col-span-1 md:col-span-3 bg-gradient-to-r from-green-400 to-blue-500 text-white p-6 rounded-lg shadow-xl">
            <h2 className="text-3xl font-bold mb-2">
              Selamat Datang, {userName}!
            </h2>
            <p className="text-lg">
              Aplikasi E-Supir untuk memastikan muatan siap, aman, dan
              pemberangkatan tepat waktu.
            </p>
          </div>

          {/* Kartu lainnya */}
          <Card icon={<FaShoppingCart />} title="Orders" value="140" />
          <Card icon={<FaUser />} title="Users" value="5" />
          <Card icon={<FaCog />} title="Settings" value="140" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
