import React, { useEffect, useState } from 'react';
import Navbar from "../shared/navbar";
import Sidebar from '../shared/sidebar';
import { FaEye } from 'react-icons/fa6';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';

const Users = () => {
    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        // Fetch the users data from the backend
        const fetchUsers = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/users');
                setUsersData(response.data); // Assuming response.data contains the users array
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleEdit = (id) => {
        console.log('Edit user with id:', id);
    };

    const handleDelete = (id) => {
        console.log('Delete user with id:', id);
    };

    const handleView = (id) => {
        console.log('View user with id:', id);
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="grow ml-16 md:ml-64 h-full lg:h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white">
                <Navbar />
                <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {usersData.map((user) => (
                        <div key={user.id} className="flex flex-col items-center bg-white shadow-md rounded-lg p-4 dark:bg-gray-800">
                            <img src="/logo192.png" alt={user.name} className="w-16 h-16 rounded-full mb-2" />
                            <h2 className="text-lg font-semibold">{user.name}</h2>
                            <p className="text-gray-500">{user.role}</p>
                            <div className="user-actions mt-3 space-x-2">
                                <button 
                                    onClick={() => handleView(user.id)} 
                                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                                >
                                    <FaEye />
                                </button>
                                <button 
                                    onClick={() => handleEdit(user.id)} 
                                    className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                                >
                                    <FaEdit />
                                </button>
                                <button 
                                    onClick={() => handleDelete(user.id)} 
                                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Users;
